.loaderBox {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #39db34;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  } 