.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  z-index: 1000;
}

.poster {
  max-width: 45rem;
  width: 100%;
  height: auto;
  max-height: 80vh;
  border-radius: 1rem;
}

.imgBox {
  position: relative;
}

.icon {
  position: absolute;
  top: 0;
  right: -3rem;
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .modal {
    margin: 0 auto;
  }

  .poster {
    width: 80vw;
  }

  .icon {
    font-size: 4rem;
    top: 0;
  }
}
