.footer {
    overflow: hidden;
    background: linear-gradient(to right, #8b0000, #b8860b);

    color: #fff;
    padding: 2rem 3rem;
    border-top-left-radius: 50px;
    font-size: 1.5rem;
    line-height: 2rem;
    position: relative;
}

.row {
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.col {
    flex-basis: 25%;
    padding: 1.3rem;
}

.karma {
    padding-bottom: 2rem;
    width: 22rem;
}

.col h3 {
    width: fit-content;
    margin-bottom: 4rem;
}

.para {
    color: #ccc;
    font-weight: 300;
    font-size: 1.2rem;
}

.email {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: fit-content;
}

.links {
    flex-basis: 15%;
}

.col ul li {
    list-style: none;
    margin-bottom: 12px;
}

.contactNo:not(:last-child) {
    margin-bottom: .5rem;
}

ul li a {
    text-decoration: none;
    cursor: pointer;
}

ul li a:hover {
    color: #ccc;
}

.icons {
    font-size: 4rem;
    margin-right: 3rem;
    text-align: center;
}

.socialIcons a {
    text-decoration: none;
    color: #fff;
}

.icons:hover {
    color: #ccc;
}

.hr {
    width: 95%;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin: 2rem auto;
}

.copyright {
    text-align: center;
    color: #ccc;
}

@media screen and (max-width: 480px) {
    .col {
        flex-basis: 25%;
        padding: 1rem;
    }

    .col:nth-child(1) {
        margin-bottom: 1rem;
    }

    .col:nth-child(2) {
        margin-bottom: 1.5rem;
    }

    .para {
        font-size: 1.6rem;
    }

    .col:nth-child(2), .col:nth-child(3), .col:nth-child(4) {
        flex-basis: 100%;
    }
}
