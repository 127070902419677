@font-face {
  font-family: myFirstFont;
  src: url(Origami.ttf);
}

.comingsoon {
  font-family: myFirstFont;
  font-size: 12rem;
  background: #7f00ff;
  background-image: linear-gradient(to bottom, #ffd700, #fff8dc);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 3rem;
  text-align: center;
}

.hero {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("/public/assets/Hero/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.herobox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4rem;
}

.headerbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-family: myFirstFont;
  font-size: 12rem;
  font-weight: bolder;
  display: inline-block;
  text-align: center;
  background-image: linear-gradient(to bottom, #ffd700, #fff8dc);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.logo {
  margin-bottom: 3.5rem;
}

.caption {
  font-style: italic;
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
}

.btn {
  display: block;
  margin-bottom: 2rem;
  background: linear-gradient(to bottom, #ffd700, #fff8dc);
  padding: 1.1rem 0.5rem;
  border: none;
  border-radius: 10px;
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
  width: 30rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  transform: translateY(-0.1rem);
  background-image: linear-gradient(to bottom, #ffd700, #fff8dc);
}

.btn:active {
  transform: translateY(0);
}

.anchorBtn {
  text-decoration: none;
}

.date {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.countdownbox {
  color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 3rem 5rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.countdown {
  font-size: 3rem;
  font-family: "Orbitron", sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.column {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .herobox {
    justify-content: center;
  }

  .hero {
    background-position: center center;
  }

  .logo {
    width: 50rem;
  }

  .headerbox {
    padding: 2rem 0;
  }

  .heading {
    font-size: 8rem;
  }

  .btn {
    display: block;
    background-image: linear-gradient(to bottom, #ffd700, #fff8dc);
    padding: 1.1rem 0.5rem;
    border: none;
    border-radius: 5px;
    font-size: 2.4rem;
    font-weight: 600;
    color: #fff;
    width: 30rem;
    cursor: pointer;
    transition: all 0.2s;
  }

  .countdownbox {
    column-gap: 1rem;
  }
}
