.dropdown {
  width: 16rem;
  position: absolute;
  top: 85px;
  right: 355px;
  text-align: left;
  background: #0e0d0d;
  border-radius: 8px;
  padding: 0.7rem 0.8rem;
}

.drops {
  cursor: pointer;
  list-style: none;
}

.links {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  opacity: 80%;
  padding: 10px;
  transition: all.5s;
}

.links:hover {
  /* background-image: linear-gradient(to bottom right,#09B326, #32B4ED);
    -webkit-background-clip: text; */
  color: var(--grad-2-light);
  transition: all.5s;
}

@media only screen and (max-width: 480px) {
  .dropdown {
    width: 30rem;
    position: absolute;
    top: 175px;
    right: 55px;
    text-align: start;
    border-radius: 9px;
  }
}
