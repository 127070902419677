.preLoader {
    position: absolute;
  
    background-color: black;
    height: 100%!important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: trans 1.4s linear 1.3s 1 normal forwards;
    z-index: 999999;
  }
  
  .preLoaderLogo {
    /* width: 100%; */
    height: 300px;
    animation: logo 5s;
    opacity: 0;
    margin-bottom: 3rem;
  }
  .preLoaderheading {
    font-family: myFirstFont;
    font-size: 10rem;
    font-weight: bolder;
    display: inline-block;
    background-image: linear-gradient(to bottom, #09b326, #32b4ed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: logo 5s;
    opacity: 0;
  }
  @keyframes logo {
    0% {
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes trans {
    from {
      margin-top: 0;
      opacity: 1;
    }
  
    to {
      margin-top: -100vh;
      opacity: 0;
    }
  }
