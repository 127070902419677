.events {
  padding: 3rem 1.5rem;
}

.eventBox {
  display: flex;
}

.imgBox {
  margin: 0 auto;
  padding: 0 4rem;
}

.headingBox {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
}

.heading {
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.para {
  font-size: 1.8rem;
  line-height: 2.9rem;
  margin-bottom: 2.5rem;
  font-weight: 300;
  color: rgb(236, 235, 235);
}

.img {
  width: 85%;
}

@media screen and (max-width: 480px) {
  .eventBox {
    flex-direction: column;
    gap: 4rem;
  }

  .btn {
    align-self: center;
  }
}
