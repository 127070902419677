.header {
  position: fixed;
  width: calc(100vw - 4rem);
  top: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 1.5rem 2rem;
  color: #fff;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

.scrolledHeader {
  width: calc(100vw - 8rem);
  top: 2rem;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  color: #fff;
  z-index: 100;
  border-radius: 15px;
  transition: all 0.4s ease-in-out;
  backdrop-filter: blur(7px);
}

.elements {
  display: flex;
  align-items: center;
}

.logobox {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-right: auto;
}

.logo {
  /* width: 25%; */
  height: 5rem;
}

.hamburger {
  display: none;
}

.heading {
  font-size: 2.1rem;
}

.navbox {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
}

.navLink {
  align-items: center;
  text-decoration: none;
  list-style: none;
  margin-right: 3rem;
  font-weight: 500;
  cursor: pointer;
}

.navLink:after {
  content: "";
  display: block;
  width: 100%;
  transform: scaleX(0);
  height: 3.5px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    var(--grad-1-light),
    var(--grad-2-light)
  );
  border-radius: 5px;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

.homeLink {
  color: inherit;
  text-decoration: none;
}

.navLink:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropLink {
  width: 8.5rem;
  display: inline-block;
  text-align: center;
  align-items: center;
  text-decoration: none;
  margin-right: 3rem;
  cursor: pointer;
  background: var(--color-1);
  -webkit-background-clip: text;
  background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  color: transparent;
  font-weight: 700;
}

.btn {
  background: var(--color-1);
  padding: 0.9rem 1.3rem;
  border: none;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  width: 12rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.btn:hover {
  transform: translateY(-0.1rem);
  background: var(--color-1);
}

.btn:active {
  transform: translateY(0);
}

.mobileBox {
  display: none;
}

.none {
  display: none;
}

.mobileNav li {
  list-style: none;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.mobileNav li:nth-child(4) {
  margin-top: 2rem;
}

.mobLink {
  display: inline;
  padding: 2rem;
  font-size: 2.7rem;background-image: linear-gradient(to bottom, #ffd700, #fff8dc);

  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  color: transparent;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .logo {
    width: auto;
    /* height: 4rem; */
  }

  .nav {
    display: none;
  }

  .btn {
    display: none;
  }

  .btn1 {
    background-image: linear-gradient(to bottom, #ffd700, #fff8dc);

    padding: 0.9rem 1.3rem;
    border: none;
    border-radius: 5px;
    font-size: 1.9rem;
    font-weight: 600;
    color: #fff;
    width: 15rem;
    margin: 2rem auto;
  }

  .hamburger {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .none {
    width: 95%;
    position: absolute;
    top: -50rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.527);
    border-radius: 10px;
    padding: 1.3rem 2rem;
    text-align: center;
    transition: all 0.4s;
  }

  .mobileBox {
    width: 95%;
    position: absolute;
    top: 12rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.89);
    border-radius: 10px;
    padding: 1.3rem 2rem;
    text-align: center;
    transition: all 0.8s;
  }
}
.header {
  position: fixed;
  width: calc(100vw - 4rem);
  top: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 1.5rem 2rem;
  color: #fff;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

.scrolledHeader {
  width: calc(100vw - 8rem);
  top: 2rem;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(88, 76, 0, 0.618); /* Gold */
  padding: 2rem;
  color: #fff;
  z-index: 100;
  border-radius: 15px;
  transition: all 0.4s ease-in-out;
  backdrop-filter: blur(7px);
}

.navLink:after {
  content: "";
  display: block;
  width: 100%;
  transform: scaleX(0);
  height: 3.5px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #ffd700, #fff8dc); /* Gold gradient */
  border-radius: 5px;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

.mobLink {
  display: inline;
  padding: 2rem;
  font-size: 2.7rem;
  background-image: linear-gradient(to bottom right, #ffd700, #fff8dc); /* Gold gradient */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700;
}

.btn {
  background: linear-gradient(to right, #ffd700, #fff8dc); /* Gold gradient */
  padding: 0.9rem 1.3rem;
  border: none;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 600;
  color: black;
  width: 12rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.btn:hover {
  transform: translateY(-0.1rem);
  background: linear-gradient(to right, #ffe135, #fffacd); /* Lighter golden hue on hover */
}

.btn1 {
  background-image: linear-gradient(to bottom right, #ffd700, #fff8dc); /* Gold gradient */
  padding: 0.9rem 1.3rem;
  border: none;
  border-radius: 5px;
  font-size: 1.9rem;
  font-weight: 600;
  color: black;
  width: 15rem;
  margin: 2rem auto;
}
