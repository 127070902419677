.aboutSec {
  height: 100%;
  padding: 3rem 1.5rem;
}

.about {
  width: 100%;
  display: flex;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.432);
  padding: 4rem 5rem;
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
  }
}

.details {
  flex-basis: 75%;
  color: #fff;
}

.heading {
  font-size: 4rem;
  font-weight: 400;
  margin-bottom: 0;
}

.heading1 {
  font-size: 9rem;
  text-transform: uppercase;
  color: transparent;
  font-weight: 700;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fff;
}

@media screen and (max-width: 480px) {
  .heading1 {
    font-size: 7.5rem;
    margin-bottom: 2.5rem;
  }
}

.para {
  font-size: 1.5rem;
  line-height: 2.5rem;
  opacity: 90%;
}

.para:not(:last-child) {
  margin-bottom: 2rem;
}

.composition {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .about {
    gap: 4rem;
  }
  .details {
    order: 2;
  }
  .composition {
    order: 1;
  }

  .heading1 {
    font-size: 7rem;
  }
}

.images {
  box-shadow: 0 1.5rem 4rem rgba(#000, 0.4);
  border-radius: 2px;
  transition: all 0.2s;
  z-index: 20;
  outline-offset: 2rem;
  width: 95%;
}
